:root {
    --background-color: #0a0a0a;
    --overlay-color: rgba(255, 255, 255, 0.08);
    --box-color: #1f1f1f;
    --tint-color: #00aac4;
    --error-color: #c40000;
    --border-radius: 0.75rem;
    --font-size-default: 14px;
    --font-size-header: 20px;
    --font-size-title: 26px;
    --padding-large: 2rem;
    --padding-medium: 1rem;
    --padding-small: 0.5rem;
}

* {
    text-decoration: none;
    font-family: inherit;
}

p {
    color: white;
    margin: unset;
    font-size: var(--font-size-default);
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: var(--box-color);
    border-radius: 50px;
}

::-webkit-scrollbar-thumb {
    background: var(--overlay-color);
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #3b424b;
}

select {
    background-color: transparent;
    border: solid 1px var(--overlay-color);
    color: white;
    outline: none;
    padding: 8px 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: var(--font-size-default);
    border-radius: var(--border-radius);
}

button, a {
    background-color: var(--overlay-color);
    color: white;
    outline: none;
    border: none;
    padding: 14px 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: var(--font-size-default);
    border-radius: var(--border-radius);
}

input, textarea {
    background-color: unset;
    border: solid 1px var(--overlay-color);
    color: white;
    outline: none;
    padding: 5px 20px;
    font-size: var(--font-size-default);
    border-radius: var(--border-radius);
    resize: none;
}

.text.header {
    font-size: 20px;
    font-weight: bold;
}

.text.regular {
    font-size: 16px;
    font-weight: 500;
}

.grid {
    display: grid;
    align-items: start;
    align-content: baseline;
}

.grid.two-columns {
    grid-template-columns: 1fr 1fr;
}

.grid.three-columns {
    grid-template-columns: 1fr 1fr 1fr;
}

.layout {
    display: flex;
}

.layout.vertical {
    flex-direction: column;
}

.layout.horizontal {
    flex-direction: row;
}

.layout.wrap {
    flex-wrap: wrap;
}

.layout.gap {
    gap: 5px;
}

.layout.gap-10 {
    gap: 10px;
}

.layout.center {
    align-items: center;
    justify-content: center;
}

.layout.stretch {
    justify-content: space-between;
}

.flex-grow {
    flex-grow: 1;
}

.flex-no-shrink {
    flex-shrink: 0;
}

.flex-1 {
    flex: 1;
}

.bar-state {
    border: solid 1px var(--overlay-color);
    cursor: pointer;
}

.bar-state-active {
    background-color: var(--overlay-color);
}

.bar-state-inactive {
    background-color: unset;
}

.export-button {
    background-color: var(--tint-color);
}

.box {
    background-color: var(--box-color);
    padding: var(--padding-medium);
    border-radius: var(--border-radius);
    border: solid 1px var(--overlay-color);
    overflow: hidden;
}

.label {
    border: solid 1px transparent;
    background-color: var(--overlay-color);
    padding: 0.8rem;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.label.selected {
    border: solid 1px rgba(255, 255, 255, 0.3);
}

.tag {
    text-transform: capitalize;
    border-radius: 50px;
    background-color: var(--overlay-color);
    padding: 5px 10px;
    overflow: hidden;
}

.overlay {
    background-color: rgba(0,0,0,0.7);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.circle-container {
    position: relative;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin: 20px;
}

.circle-item {
    position: absolute;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    background-color: var(--overlay-color);
    color: white;
}

.preview-img {
    height: 160px;
    width: 320px;
    border-radius: var(--border-radius);
    border: solid 1px var(--overlay-color);
}

.date-picker {
    width: 80px;
    padding: 10px;
    text-align: center;
}

.red {
    background-color: #ff5454;
    box-shadow: 0px 0px 100px 20px #ff5454;
}

.yellow {
    background-color: #ff8f34;
    box-shadow: 0px 0px 100px 20px #ff8f34;
}

.green {
    background-color: #3fff88;
    box-shadow: 0px 0px 100px 20px #3fff88;
    border: solid 1px #3fff88;
}

.status-explainer {
    padding: 10px;
    gap: var(--padding-small);
}

.section {
    border-radius: var(--border-radius);
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0.75rem;
    gap: 0.75rem;
}

@media (hover: hover) {
    button, .label {
        cursor: pointer;
        transition: 0.2s ease;
    }
    
    button:hover, .label:hover {
        background-color: rgba(255, 255, 255, 0.14);
    }
}

@media screen and (max-width: 900px) {
    :root {
        --font-size-default: 12px;
        --font-size-header: 16px;
        --font-size-title: 20px;
        --seq-space-4: 0.8rem;
        --padding-large: 1.5rem;
        --padding-medium: 0.75rem;
        --padding-small: 0.25rem;
    }

    .status-explainer {
        padding: 5px;
    }
}
